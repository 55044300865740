<template>
  <button
    class="d-flex align-center jc-space-between w-100 mb-24"
    @click.prevent="profilesShow = !profilesShow"
  >
    <h4
      class="text-h4 test-results"
      :class="[`test-results-${testTitle}`]"
    >{{ searchTitle }}</h4>
    <div class="text-h4 color-text-lighter">
      <span class="mr-14">{{ results.length }} result(s)</span>
      <Icon
        name="arrow-down"
        class="color-link mt-3"
        :class="{ 'rotate--180 mb-2' : profilesShow }"
      />
    </div>
  </button>

  <div
    v-show="profilesShow"
    class="d-grid gtc-4 gap-12 mb-32"
  >
    <template
      v-for="(result, index) in limitedResults"
      :key="result.oid"
    >
      <Waypoint
        class="min-w-0"
        :active="(index + 1) === limitedResults.length"
        :disableCssHelpers="true"
        @change="waypointMethod"
      >
        <ProfileResult :result="result" />
      </Waypoint>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import ProfileResult from '@/components/search/ProfileResult';
import { Waypoint } from 'vue-waypoint';
import { mapGetters } from 'vuex';
import relevance from 'relevance';
import { getProfileName } from '@/utils/helpers';

export default {
  components: {
    Icon,
    ProfileResult,
    Waypoint
  },
  props: {
    title: {
      default: null
    },
    results: {
      default: []
    },
    searchType: {
      default: null
    }
  },
  data () {
    return {
      profilesShow: true,
      limitToShow: 20,
      limitStep: 20
    };
  },
  computed: {
    ...mapGetters(['getCurrentSearches']),

    testTitle () {
      if (this.searchType) return this.searchType.replace(',', '-');
      else return '';
    },
    searchTitle () {
      let overLimit = '';

      if (this.searchType) {
        const searchQuery = this.getCurrentSearches?.query || {};
        let searchTitle = '';
        for (let searchType of this.searchType.split(',')) {
          if (searchType === 'photo') searchType = 'image';
          let searchValue = searchQuery[searchType];
          if (searchValue) {
            const loadedImage = searchValue.startsWith(process.env.VUE_APP_IMAGES_SERVER_PROD) || searchValue.startsWith(process.env.VUE_APP_IMAGES_SERVER_TEST);
            if (searchType === 'image' && loadedImage) {
              searchValue = searchValue.split('/').pop();
            }
            searchTitle += `${searchType.charAt(0).toUpperCase() + searchType.replace('_', ' ').slice(1)}: ${searchValue} & `;
          }
        }
        searchTitle = searchTitle.slice(0, searchTitle.length - 3);
        if (searchTitle.length > 80) overLimit = '...';
        return searchTitle.slice(0, 80) + overLimit;
      } else {
        if (this.title.length > 80) overLimit = '...';
        return this.title.slice(0, 80) + overLimit;
      }
    },
    sortedResults () {
      const search = Object.values(this.getCurrentSearches.query).filter(item => item);
      const query = [this.getCurrentSearches.query.name];
      let sorted = [];

      this.results.forEach(element => {
        if (this.getCurrentSearches.query.name) element.sortedName = getProfileName(element);
        else if (this.getCurrentSearches.query.profile_id) element.sortedName = this.getCurrentSearches.query.profile_id;
        else if (this.getCurrentSearches.query.profile_url) element.sortedName = this.getCurrentSearches.query.profile_url;
        if (this.searchType === 'name,education' || this.searchType === 'name,company' || this.searchType === 'name,location') {
          element.sortedInfo = element.fields.info ? element.fields.info : '';
        }
        if (this.searchType === 'alias') element.sortedAlias = this.alias(element);
      });

      switch (this.searchType) {
        case 'name,education':
        case 'name,company':
        case 'name,location':
          if (this.searchType === 'name,education') query.push(this.getCurrentSearches.query.education);
          if (this.searchType === 'name,company') query.push(this.getCurrentSearches.query.company);
          if (this.searchType === 'name,location') query.push(this.getCurrentSearches.query.location);

          sorted = relevance({
            query: query,
            data: this.results,
            rankings: {
              sortedName: 2,
              sortedInfo: 1
            }
          });
          break;
        case 'alias':
          if (this.getCurrentSearches.query.name) {
            sorted = relevance({
              query: [this.getCurrentSearches.query.name, this.getCurrentSearches.query.alias],
              data: this.results,
              rankings: {
                sortedName: 1,
                sortedAlias: 5
              }
            });
          } else {
            sorted = relevance({
              query: [this.getCurrentSearches.query.alias],
              data: this.results,
              rankings: {
                sortedAlias: 5
              }
            });
          }
          break;
        case 'email':
          if (!this.getCurrentSearches.query.name) break;
          // don't move it upper, default statement should go next, because no 'break' here
        // eslint-disable-next-line no-fallthrough
        default:
          sorted = relevance({
            query: this.searchType === 'email' ? [this.getCurrentSearches.query.name] : search,
            data: this.results,
            rankings: {
              sortedName: 5
            }
          });
          break;
      }

      return sorted.length > 0 ? sorted : this.results;
    },
    limitedResults () {
      if (this.sortedResults) return this.sortedResults.slice(0, this.limitToShow);
      else return [];
    }
  },
  methods: {
    waypointMethod (waypointState) {
      if (waypointState.going === 'IN') {
        this.limitToShow += this.limitStep;
      }
    },
    alias (profile) {
      const fields = profile.fields;
      let alias = fields?.alias;
      if (!alias) alias = fields && fields['twitter.screen-name'];
      if (!alias) alias = fields?.username;
      return (alias?.value) || '';
    }
  }
};
</script>
