<template>
  <div
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    class="bg-white br-5 h-100 p-12 transition"
    :class="{
      'bs-default': !hovered,
      'bs-none': hovered
    }"
  >
    <div class="d-flex align-center jc-space-between mb-16">
      <a
        class="d-flex align-center text-link"
        target="_blank"
        :href="profileUrl"
      >
        <Icon :name="socialNetworkIcon" />
        <span class="ml-10">Profile</span>
      </a>
      <ProfileCheckbox :profile="result" />
    </div>
    <div class="p-relative br-circle bg-body w-106px h-106px p-5 ml-auto mr-auto mb-12">
      <Avatar
        :src="avatar"
        class="br-circle w-96px h-96px"
      />
      <div
        @click.stop="setOpenRightSidebar(result)"
        class="p-absolute br-circle bg-link-lighter bg-link_hover cursor-pointer transition top-5px left-5px right-5px bottom-5px"
        :class="{
          'opacity-0': !hovered,
          'opacity-10': hovered
        }"
      >
        <div class="p-absolute abs-center d-grid gtc-3 gap-6">
          <div class="br-circle w-8px h-8px bg-white"></div>
          <div class="br-circle w-8px h-8px bg-white"></div>
          <div class="br-circle w-8px h-8px bg-white"></div>
        </div>
      </div>
    </div>
    <div class="text-h4 color-link text-center overflow-hidden mb-2">{{ name }}</div>
    <div class="color-text-lighter text-center">{{ location }}</div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import ProfileCheckbox from '@/components/app/ProfileCheckbox';
import Avatar from '@/components/app/Avatar';
import { getProfileLocation, getProfileName, getProfileAvatar, getProfileUrl } from '@/utils/helpers';
import { mapActions } from 'vuex';

export default {
  components: {
    Icon,
    Avatar,
    ProfileCheckbox
  },
  props: ['result'],
  data () {
    return {
      hovered: false
    };
  },
  computed: {
    name () {
      return getProfileName(this.result);
    },
    location () {
      return getProfileLocation(this.result);
    },
    avatar () {
      return getProfileAvatar(this.result);
    },
    profileUrl () {
      return getProfileUrl(this.result);
    },
    socialNetworkIcon () {
      let socialNetwork = '';
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (this.result.typeid.includes(socialNet)) {
          socialNetwork = 'person-card-' + socialNet.toLowerCase();
        }
      }
      return socialNetwork;
    }
  },
  methods: {
    ...mapActions(['setOpenRightSidebar', 'setUpdateResults'])
  }
};
</script>
