<template>
  <div
    class="d-flex align-center jc-space-between transition bg-white b-all-double px-20 py-12 my-4"
    :class="{
      'bc-white': !checked,
      'bc-link': checked
    }"
  >
    <div class="d-flex align-center">
      <Avatar class="w-24px h-24px br-circle mr-8" :src="avatar" />
      <div class="w-24px h-24px br-circle bg-form d-flex align-center jc-center mr-8">
        <Icon :name="socialNetworkIcon" />
      </div>
      <div class="text-base">{{ name }} <span v-if="location" class="color-link">&nbsp;({{ location }})</span></div>
    </div>
    <div class="d-flex align-center">
      <button class="text-link mr-24" @click="setOpenRightSidebar(profile)">User details</button>
      <button class="text-link mr-24" @click="showOnMap">Show on map</button>
      <ProfileCheckbox :profile="profile" />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/app/Avatar';
import Icon from '@/components/app/Icon';
import profileInfoMixin from '@/mixins/profileInfoMixin';
import ProfileCheckbox from '@/components/app/ProfileCheckbox';
import { mapActions } from 'vuex';

export default {
  name: 'MapViewProfile',
  components: {
    Avatar,
    Icon,
    ProfileCheckbox
  },
  props: ['profile'],
  mixins: [profileInfoMixin],
  data () {
    return {
      checked: this.profile.checked
    };
  },
  methods: {
    ...mapActions(['setOpenRightSidebar', 'focusEntityOnMap', 'setUpdateResults']),

    async showOnMap () {
      await this.focusEntityOnMap(this.profile);
      window.scrollTo(0, 200);
    },
    checkResult () {
      const updatedResult = { ...this.profile, ...{ checked: this.checked } };
      this.setUpdateResults(updatedResult);
    }
  }
};
</script>
