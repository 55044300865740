<template>
  <div
    v-if="!getLocatedResults.length"
    id="mapview-loader"
  >
    <img src="@/assets/icons/searching.gif" alt="" class="h-78 mb-12">
    <span class="typography-3 color-2">Loading...</span>
  </div>
  <Map
    v-else
    :entities="getLocatedResults"
    type="results"
  />
  <div
    v-if="getLocatedResults.length"
    class="map-profiles bg-form pt-0 px-24 pb-24"
  >
    <div v-for="country in getCountriesResults" :key="country" class="pt-16">
      <MapViewAccordion v-if="country" :country="country" :profiles="getLocatedResults" />
    </div>
  </div>
</template>

<script>
import Map from '@/components/map-new/Map';
import MapViewAccordion from '@/components/map-new/MapViewAccordion';
import { mapGetters } from 'vuex';

export default {
  name: 'MapView',
  computed: {
    ...mapGetters(['getLocatedResults', 'getCountriesResults'])
  },
  components: {
    Map, MapViewAccordion
  }
};
</script>

<style lang="scss" scoped>
  #mapview-loader {
    height: 400px;
    background-color: #F8FCFF;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
