import { getProfileLocation, getProfileName, getProfileAvatar, getProfileUrl } from '@/utils/helpers';

export default {
  computed: {
    name () {
      return getProfileName(this.profile);
    },
    location () {
      return getProfileLocation(this.profile);
    },
    avatar () {
      return getProfileAvatar(this.profile);
    },
    profileUrl () {
      return getProfileUrl(this.profile);
    },
    socialNetworkIcon () {
      let socialNetwork = '';
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (this.profile.typeid.includes(socialNet)) {
          socialNetwork = 'person-card-' + socialNet.toLowerCase();
        }
      }
      return socialNetwork;
    },
    profileDetails () {
      if (this.profile.fields['user-details']) return JSON.parse(this.profile.fields['user-details']);
      else return {};
    }
  }
};
