<template>
  <div class="bg-form bs-default br-5 p-24 mb-32">
    <div class="d-flex align-center jc-space-between mb-16">
      <h4 class="text-h4">Search input</h4>
      <!-- <button
        class="text-link f-flex align-center"
      >
        <Icon name="refresh" class="transition" />
        <span class="ml-4">Refresh ({{ refreshPrice }} SLP)</span>
      </button> -->
    </div>
    <div class="d-grid gtc-auto-260px gap-12 align-center mt-24">
      <MultiSelect
        :options="searchQuery"
      />
      <router-link
        class="bttn-transparent w-260px"
        :to="'/new-search/' + $route.params.id + '/' + $route.params.searchId"
      >
        <Icon name="arrow-left" class="h-12px mr-12" />
        Change
      </router-link>
    </div>
  </div>

  <CaseTitleAndTabs
    class="mb-16"
    :toggleView="tabShow"
    :title="resultsTitle"
    @activeTab="toggleTabs"
  />

  <template v-if="tabShow === ''">
    <Filter
      v-if="getCurrentResults.length > 1"
      :getters="{ current: getCurrentResults, visible: getVisibleResults, checked: getCheckedValues }"
    />
    <template v-if="searchTypes.length && searchTypesLoaded">
      <ResultsGroup
        v-for="(searchType, index) in searchTypes"
        :key="index"
        :search-type="searchType"
        :results="getVisibleResults.filter(ent => ent.fields['search-type'] === searchType)"
      />
    </template>
    <template v-else-if="searchTypesLoaded">
      <ResultsGroup
        :title="getCurrentSearches.tag"
        :results="getVisibleResults"
      />
    </template>
  </template>

  <template v-if="tabShow === 'map'">
    <MapView />
  </template>

  <Controller
    v-if="Object.keys(getCheckedValues).length"
    :profiles="resultsForController"
  />
</template>

<script>
import Icon from '@/components/app/Icon';
import MultiSelect from '@/components/search/MultiSelect';
import Filter from '@/components/UI/Filter';
import CaseTitleAndTabs from '@/components/UI/CaseTitleAndTabs';
import ResultsGroup from '@/components/search/ResultsGroup';
import MapView from '@/components/map-new/MapView';
import Controller from '@/components/controller/Template';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    MultiSelect,
    Filter,
    CaseTitleAndTabs,
    ResultsGroup,
    MapView,
    Controller
  },
  data () {
    return {
      tabShow: '',
      sortedSearchTypes: ['phone', 'email', 'name,photo', 'name,education', 'name,company', 'name,location', 'alias', 'profile_url', 'profile_id', 'name'],
      searchTypesLoaded: false
    };
  },
  created () {
    this.setCurrentSearches();
  },
  watch: {
    $route: {
      immediate: true,
      async handler () {
        this.tabShow = '';
        if (this.$route.params.searchId) {
          await this.setCurrentResults();
          if (!this.getCurrentResults.length) {
            this.$router.push(`/new-search/${this.$route.params.id}/${this.$route.params.searchId}?zeroresults=true`);
          }
        }
      }
    },
    searchTypes: {
      immediate: true,
      handler () {
        if (this.searchTypes.length) this.searchTypesLoaded = true;
      }
    },
    tabShow: {
      immediate: true,
      handler () {
        if (this.tabShow === 'map') this.setLatLon();
      }
    }
  },
  computed: {
    ...mapGetters(['getCurrentSearches', 'getCurrentResults', 'getVisibleResults', 'getLocatedResults', 'getCheckedValues']),

    searchQuery () {
      if (this.getCurrentSearches) {
        const queries = { ...this.getCurrentSearches.query };
        const newQueries = [];

        for (const query in queries) {
          if (queries[query].length) {
            const price = this.getQueryPrice(query);
            const obj = { type: query, title: queries[query], price };
            newQueries.push(obj);
          }
        }
        return newQueries;
      } else {
        return [];
      }
    },
    refreshPrice () {
      if (this.searchQuery.length) {
        return this.searchQuery.reduce((prevPrice, currPrice) => prevPrice + currPrice.price, 0);
      } else return 0;
    },
    searchTypes () {
      const searchTypes = [];
      this.getVisibleResults.forEach(ent => {
        const searchType = ent.fields['search-type'];
        if (searchType) searchTypes.push(searchType);
      });
      const uniqueAndSortedSearchTypes = [];
      for (const searchType of this.sortedSearchTypes) {
        if (searchTypes.includes(searchType)) uniqueAndSortedSearchTypes.push(searchType);
      }
      return uniqueAndSortedSearchTypes;
    },
    resultsTitle () {
      if (this.tabShow === '') return this.getVisibleResults.length + ' result(s)';
      else return this.getLocatedResults.length + ' result(s)';
    },
    resultsForController () {
      const arr = [];
      for (const key of Object.keys(this.getCheckedValues)) {
        arr.push(this.getCheckedValues[key]);
      }
      return arr;
    }
  },
  methods: {
    ...mapActions(['setCurrentSearches', 'setCurrentResults', 'setLatLon']),
    toggleTabs (val) {
      this.tabShow = val;
    },
    getQueryPrice (query) {
      let price = 0;
      if (query === 'name') price = 12;
      else if (query === 'alias') price = 12;
      else if (query === 'image') price = 12;
      else if (query === 'location') price = 6;
      else if (query === 'phone') price = 17;
      else if (query === 'email') price = 18;
      else if (query === 'education') price = 3;
      else if (query === 'company') price = 3;
      else if (query === 'profile_url') price = 4;
      else if (query === 'profile_id') price = 5;

      return price;
    }
  }
};
</script>
