<template>
  <div class="d-flex align-center jc-space-between">
    <div>
      <h3 :class="titleClass">{{ title }}</h3>
    </div>
    <div
      class="d-flex align-center br-5 p-4"
      :class="bgClass"
    >
      <button
        @click.prevent="$emit('activeTab', tabTrigger1)"
        class="py-6 px-20"
        :class="{
          'bttn-primary': toggleView === tabTrigger1,
          'bttn-white': toggleView !== tabTrigger1,
        }"
      >
        <Icon :name="tabIcon1" />
        <span class="ml-10">{{ tabTitle1 }}</span>
      </button>
      <button
        @click.prevent="$emit('activeTab', tabTrigger2)"
        class="py-6 px-20 ml-4"
        :class="{
          'bttn-primary': toggleView === tabTrigger2,
          'bttn-white': toggleView !== tabTrigger2,
        }"
      >
        <Icon :name="tabIcon2" />
        <span class="ml-10">{{ tabTitle2 }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  components: {
    Icon
  },
  props: {
    bgClass: { default: 'bg-form' },
    toggleView: { default: '' },
    title: { default: '' },
    titleClass: { default: 'text-h3' },

    tabTitle1: { default: 'Show on overview' },
    tabIcon1: { default: 'window-new' },
    tabTrigger1: { default: '' },

    tabTitle2: { default: 'Show on map' },
    tabIcon2: { default: 'location-marker-new' },
    tabTrigger2: { default: 'map' }
  },
  emits: ['activeTab']
};
</script>
