<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarCase />
    </template>
    <template v-slot:content>
      <SearchProfile />
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarCase from '@/viewsNew/SidebarCase';
import SearchProfile from '@/components/search/Profile';

export default {
  components: {
    Layout,
    SidebarCase,
    SearchProfile
  }
};
</script>
